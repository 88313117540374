import React from 'react';
import './UnderConstruction.css'; // Importieren Sie Ihre CSS-Datei

const UnderConstruction = () => {
  return (
    <div className="container">
      <h1>🚗🚌🚈🚲</h1>
      <p>Immobility befindet sich aktuell noch im Aufbau, schau doch später erneut vorbei!</p>
    </div>
  );
};

export default UnderConstruction;
